
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine } from "@/utils/common";
import t from "@common/src/i18n/t";
const detecationTemplate = namespace("detecationTemplate");

@Component({})
export default class TestItemEdit extends Vue {
  @detecationTemplate.Action getDetecationTemplateDetail;
  emptyToLine = emptyToLine;
  get breadData() {
    return [
      { name: "养车日历模板", path: "/maintenance/detecationTemplate/list" },
      { name: "模板查看" }
    ];
  }
  get templateId() {
    return this.$route.params.templateId;
  }
  created() {
    this.init();
  }
  templateInfo: any = {};

  templateInfoItemList: any =[]

  init() {
    this.getDetecationTemplateDetail({
      templateId: this.templateId
    }).then(data => {
      this.templateInfo = data.data;
      this.templateInfoItemList = data.data.templateItems
      // Object.assign(this.templateInfo, data.data);
      // Object.assign(this.templateInfoItemList, data.data.templateItems);
      console.log("templateInfo", this.templateInfo)
      console.log("templateInfoItemList", this.templateInfoItemList)
    });
  }
}
